<template>
  <div class="editor-test-container">
    <h2 class="test-header">상세 페이지 편집기</h2>

    <!-- ImageEditor 컴포넌트에 html props 전달 및 save 이벤트 리스닝 -->
    <div class="editor-wrapper">
      <ImageEditor 
        ref="imageEditor" 
        :html="testHtml" 
        @save="handleSave"
      />
    </div>

    <!-- 저장된 결과 미리보기 영역 -->
    <div v-if="savedContent" class="preview-container">
      <h3>저장된 내용 미리보기</h3>
      <div class="preview-content" v-html="savedContent.html"></div>
    </div>
  </div>
</template>

<script>
import ImageEditor from './ImageEditor.vue'

export default {
  name: 'ImageEditorTest',
  components: {
    ImageEditor
  },
  data() {
    return {
      // 테스트용 HTML
      testHtml: `
        <div style="width: 100%; max-width: 800px; margin: 0 auto; padding: 20px;">
          <h2 style="text-align: center; color: #333; margin-bottom: 20px;">상품 상세 정보</h2>
          
          <div style="position: relative; margin-bottom: 30px;">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01RKb5vS2DAeiSPC9kF_!!966118569-0-cib.jpg" style="display: block; max-width: 100%;" alt="상품 이미지" />
            <div style="position: absolute; top: 30px; left: 30px; background: rgba(255,255,255,0.8); padding: 15px; border-radius: 5px;">
              <h3 style="color: #333; margin-bottom: 10px;">프리미엄 제품</h3>
              <p style="color: #666;">최고급 품질로 제작되었습니다.</p>
            </div>
          </div>
          
          <p style="text-align: center; color: #666; line-height: 1.6; margin-bottom: 20px;">
            이 제품은 최고급 소재를 사용하여 제작되었으며, 오랜 시간 동안 사용할 수 있도록 설계되었습니다.
            다양한 환경에서 테스트를 거쳐 내구성과 편의성을 모두 갖추었습니다.
          </p>
          
          <h3 style="text-align: center; color: #333; margin: 30px 0 15px;">주요 특징</h3>
          <ul style="padding-left: 30px; max-width: 600px; margin: 0 auto 30px;">
            <li style="margin-bottom: 10px;">고품질 소재 사용</li>
            <li style="margin-bottom: 10px;">편리한 사용법</li>
            <li>세련된 디자인</li>
          </ul>
          
          <div style="text-align: center; margin-top: 20px; padding: 15px; background-color: #f8f9fa; border-radius: 5px;">
            <p style="color: #666; margin-bottom: 5px;">배송: 주문 후 1-3일 이내 출고</p>
            <p style="color: #666; margin-bottom: 5px;">A/S: 구매일로부터 1년 무상 보증</p>
            <p style="color: #666;">문의: 고객센터 1588-0000</p>
          </div>
        </div>
      `,
      // 저장된 콘텐츠 저장
      savedContent: null
    }
  },
  methods: {
    // 저장 이벤트 핸들러
    handleSave(content) {
      console.log('에디터에서 저장된 내용:', content);
      this.savedContent = content;
      
      // 필요 시 API로 전송하는 로직 추가
    }
  }
}
</script>

<style scoped>
.editor-test-container {
  width: 100%;
  padding: 20px;
}

.test-header {
  margin-bottom: 20px;
  color: #333;
}

.editor-wrapper {
  width: 100%;
  height: 1500px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
}

.preview-container {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.preview-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
</style>